import React from "react"
import FAQsMobile from "../../../../../components/body/pages/mobile/uk/faq"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"

const FAQSPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/faq/"}
      title="Frequently Asked Questions | Kuda | The Money App for Africans"
      description="You've got questions and we've got answers, find answers to our most frequently asked questions here. You can also find these questions in the app."
    />
    <FAQsMobile />
  </NoheaderFooter>
)

export default FAQSPage
